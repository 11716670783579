import { combineReducers } from "redux";
import theme from "./theme/themeSlice";
import auth from "./auth";
import base from "./base";
import displaySpecialities from "./displaySpecialities";
import displayTreatment from "./displayTreatment";
import displaySuperSpec from "./displaySuperSpec";
import displayProcedures from "./displayProcedures";
import displayConditions from "./displayConditions";

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    base,
    displaySpecialities,
    displayTreatment,
    displaySuperSpec,
    displayProcedures,
    displayConditions,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
