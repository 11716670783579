import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const displaySpecialitiesSlice = createSlice({
  name: "displaySpecialities",
  initialState,
  reducers: {
    setDisplaySpecialitiesData: (state, action) => {
      state.setDisplaySpecialitiesData = action.payload;
    },
    setDisplaySpecialitiesOptions: (state, action) => {
      state.setDisplaySpecialitiesOptions = action.payload;
    },
  },
});

export const { setDisplaySpecialitiesData, setDisplaySpecialitiesOptions } =
  displaySpecialitiesSlice.actions;

export default displaySpecialitiesSlice.reducer;
