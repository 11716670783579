import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const displayProceduresSlice = createSlice({
  name: "displayProcedures",
  initialState,
  reducers: {
    setDisplayProceduresData: (state, action) => {
      state.setDisplayProceduresData = action.payload;
    },
    setDisplayProceduresOptions: (state, action) => {
      state.setDisplayProceduresOptions = action.payload;
    },
  },
});

export const { setDisplayProceduresData, setDisplayProceduresOptions } =
  displayProceduresSlice.actions;

export default displayProceduresSlice.reducer;
