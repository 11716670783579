import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const displaySuperSpecSlice = createSlice({
  name: "displaySuperSpec",
  initialState,
  reducers: {
    setDisplaySuperSpecData: (state, action) => {
      state.setDisplaySuperSpecData = action.payload;
    },
    setDisplaySuperSpecOptions: (state, action) => {
      state.setDisplaySuperSpecOptions = action.payload;
    },
  },
});

export const { setDisplaySuperSpecData, setDisplaySuperSpecOptions } =
  displaySuperSpecSlice.actions;

export default displaySuperSpecSlice.reducer;
