import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const displayTreatmentSlice = createSlice({
  name: "displayTreatment",
  initialState,
  reducers: {
    setDisplayTreatmentData: (state, action) => {
      state.setDisplayTreatmentData = action.payload;
    },
    setDisplayTreatmentOptions: (state, action) => {
      state.setDisplayTreatmentOptions = action.payload;
    },
  },
});

export const { setDisplayTreatmentData, setDisplayTreatmentOptions } =
  displayTreatmentSlice.actions;

export default displayTreatmentSlice.reducer;
