import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const displayConditionsSlice = createSlice({
  name: "displayConditions",
  initialState,
  reducers: {
    setDisplayConditionsData: (state, action) => {
      state.setDisplayConditionsData = action.payload;
    },
    setDisplayConditionsOptions: (state, action) => {
      state.setDisplayConditionsOptions = action.payload;
    },
  },
});

export const { setDisplayConditionsData, setDisplayConditionsOptions } =
  displayConditionsSlice.actions;

export default displayConditionsSlice.reducer;
